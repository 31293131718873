:root {
  --font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Segoe UI Emoji", "Apple Color Emoji", "Noto Color Emoji", sans-serif;
  --accent-h: 217.75deg;
  --accent-s: 98.36%;
  --accent-l: 52.16%;
  --accent: hsl(var(--accent-h), var(--accent-s), var(--accent-l));
  --background-body: #fff;
  --background-alt: #f7f7f7;
  --background: #efefef;
  --text-main: #363636;
  --text-muted: #70777f;
  --links: var(--accent);
  --focus: var(--accent);
  --border: #b9b8b8;
  --error-color: #dc3545;
  --success-color: #28a745;
  --dialog-background: #fff;
  --scan-frame-color: #ffffffe6;
  --dropzone-background: var(--background-alt);
  --dropzone-background-dragover: var(--background);
  --dropzone-background-hover: var(--background);
  --empty-history-button-color: #fff;
  --body-max-width: 75rem;
  --border-radius: 6px;
  --animation-duration: .1s;
  --container-max-width: 37.5rem;
  --capture-border-width: 3px;
  --video-border-radius: calc(var(--border-radius) / 2);
  --track-control-button-dimensions: 2.25rem;
  accent-color: var(--accent);
}

@media (prefers-color-scheme: dark) {
  :root {
    --accent-h: 206;
    --accent-s: 100%;
    --accent-l: 73%;
    --background-body: #fafafa;
    --background-alt: #343a40;
    --background: var(--background-alt);
    --text-main: #dbdbdb;
    --text-muted: #a9b1ba;
    --links: var(--accent);
    --focus: var(--accent);
    --border: #fafafa;
    --error-color: #ff808d;
    --success-color: #75b798;
    --dialog-background: var(--background-alt);
    --dropzone-background: #1a1c1e;
    --dropzone-background-dragover: #161718;
    --dropzone-background-hover: #161718;
    --empty-history-button-color: #000;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  max-width: var(--body-max-width);
  word-wrap: break-word;
  color: var(--text-main);
  background: var(--background-body);
  text-rendering: optimizelegibility;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

body:has(.popover-dialog[open]) {
  overflow-y: hidden;
}

[hidden] {
  display: none !important;
}

a {
  color: var(--links);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a > code, a > strong {
  color: inherit;
}

a, button, input, custom-clipboard-copy::part(button) {
  outline-color: var(--accent);
}

button {
  color: var(--text-main);
  font-family: inherit;
  font-size: inherit;
  border-radius: var(--border-radius);
  border: none;
  padding: .625rem;
}

button:not(:disabled) {
  cursor: pointer;
}

input[type="checkbox"] {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
}

input[type="checkbox"]:not(:disabled) {
  cursor: pointer;
}

kbd {
  background: var(--background);
  border: 1px solid var(--border);
  color: var(--text-main);
  border-radius: 2px;
  padding: .125rem .25rem;
}

img, video {
  max-width: 100%;
  height: auto;
}

hr {
  border: none;
  border-top: 1px solid var(--border);
}

h1 {
  margin: .75rem 0;
  font-size: 1.6rem;
  font-weight: 300;
}

dialog {
  background-color: var(--dialog-background);
  color: var(--text-main);
  border-radius: var(--border-radius);
  border: none;
  border-color: var(--border);
  padding: 10px 30px;
}

dialog::backdrop {
  backdrop-filter: blur(4px);
  background: #0000009c;
}

.noscript {
  text-align: center;
  color: #842029;
  background-color: #f8d7da;
  width: 100%;
  padding: .5rem 1rem;
  font-size: .9rem;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.site-header {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  display: flex;
}

.container {
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.alert {
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1rem;
  padding: .5rem 1rem;
  font-size: .95rem;
  display: flex;
}

.alert button {
  z-index: 2;
  color: inherit;
  background-color: #0000;
  width: 35px;
  min-width: 35px;
  margin: 0;
  padding: .15rem;
  font-size: 1.2rem;
  line-height: 1;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: .25rem;
}

.source-code {
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.scan-button {
  color: #fff;
  backdrop-filter: blur(6px);
  font-family: var(--font-family);
  text-wrap: balance;
  outline-offset: -2px;
  border-radius: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: .5rem;
  font-size: .9375rem;
  line-height: 1.5;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000b3 !important;
}

.scan-button kbd {
  font-size: .8rem;
}

.results {
  border: 1px solid var(--focus);
  background-color: var(--background-alt);
  border-radius: 6px;
  width: 100%;
  margin: 1.5rem 0 0;
  padding: 1rem;
  position: relative;
}

.results__item {
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.results__item--no-barcode {
  color: var(--error-color);
}

.results__actions {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.results web-share button, .results custom-clipboard-copy::part(button) {
  border-radius: var(--border-radius);
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  margin: .75rem 0 0;
  padding: .25rem;
  font-family: inherit;
  font-size: .9rem;
  line-height: 1;
  display: flex;
}

.scan-instructions {
  text-align: center;
  text-wrap: balance;
  font-size: .95rem;
}

.scan-frame-container {
  max-width: var(--container-max-width);
  margin: 0 auto;
  position: relative;
}

.scan-frame-container:has(capture-photo[loading]) .scan-frame {
  display: none;
}

.scan-frame {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.9);
}

.scan-frame svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

select[name="scanMethod"] {
  width: 100%;
  margin: 0 0 1.5rem;
}

input[type="file"]::-webkit-file-upload-button {
  color: var(--background);
  background-color: var(--accent);
  cursor: pointer;
  appearance: none;
  border: 0;
  border-radius: .25rem;
  margin-right: .5rem;
  padding-inline: .5rem;
  line-height: 1.6;
}

input[type="file"]:disabled::-webkit-file-upload-button {
  cursor: not-allowed;
}

.dropzone {
  --dropzone-focus-box-shadow: inset 0 0 0 2px var(--focus);
  --dropzone-transition-duration: var(--animation-duration);
  --dropzone-border-color: var(--border);
  --dropzone-border-color-dragover: var(--accent);
  --dropzone-border-color-hover: var(--accent);
  --dropzone-background-color: var(--dropzone-background);
  --dropzone-background-color-dragover: var(--dropzone-background-dragover);
  --dropzone-background-color-hover: var(--dropzone-background-hover);
  --dropzone-body-color: var(--text-main);
}

.dropzone::part(dropzone) {
  border-radius: var(--border-radius);
  flex-direction: column;
  justify-content: center;
  min-height: 15.625rem;
  margin: 0 auto;
  display: flex;
}

.dropzone-preview {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

.dropzone-preview__image-wrapper {
  max-width: 200px;
  margin: 0 auto;
}

.dropzone-preview__file-name {
  color: var(--text-main);
  font-size: .9rem;
}

.dropzone-instructions {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

body > footer {
  border-top: 1px solid var(--border);
  color: var(--text-muted);
  text-align: center;
  margin-top: 1.5rem;
  padding-top: 10px;
}

.supported-formats:empty {
  display: none;
}

.supported-formats {
  max-width: var(--container-max-width);
  text-align: center;
  color: var(--text-muted);
  margin: 1rem auto 0;
  padding: 0 .75rem;
  font-size: .75rem;
}

capture-photo {
  border: var(--capture-border-width) solid var(--border);
  border-radius: var(--border-radius);
  background-color: #000;
  justify-content: center;
  margin: 0 auto 1.5rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

capture-photo::part(video) {
  border-radius: var(--video-border-radius);
  background-color: #000;
  width: 100%;
  height: calc(100% - 10px);
}

capture-photo[loading]::part(video) {
  background-image: url("spinner-light.4452ed29.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3.75rem;
}

capture-photo::part(facing-mode-button) {
  width: var(--track-control-button-dimensions);
  height: var(--track-control-button-dimensions);
  border-radius: var(--video-border-radius);
  background-color: var(--background);
  color: var(--text-main);
  cursor: pointer;
  appearance: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 1rem;
  transition: background-color .15s ease-in-out;
  display: flex;
  position: absolute;
  bottom: .25rem;
  left: .25rem;
}

.zoom-controls {
  display: flex;
  position: absolute;
  bottom: .25rem;
  right: .25rem;
}

.torch-button, .zoom-controls button, .zoom-controls label {
  width: var(--track-control-button-dimensions);
  height: var(--track-control-button-dimensions);
  background-color: var(--background);
  color: var(--text-main);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.torch-button {
  bottom: .25rem;
  left: calc(var(--track-control-button-dimensions)  + .5rem);
  border-radius: var(--video-border-radius);
  position: absolute;
}

.zoom-controls button[data-action="zoom-in"] {
  border-top-right-radius: var(--video-border-radius);
  border-bottom-right-radius: var(--video-border-radius);
}

.zoom-controls button[data-action="zoom-out"] {
  border-top-left-radius: var(--video-border-radius);
  border-bottom-left-radius: var(--video-border-radius);
}

.zoom-controls label {
  width: 1.5625rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: .9rem;
  overflow: hidden;
}

.global-actions {
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
}

.global-actions button {
  color: var(--text-main);
  justify-content: center;
  align-items: center;
  gap: .35rem;
  margin: 0;
  padding: 8px;
  font-size: .9rem;
  line-height: 1;
  display: inline-flex;
  background-color: #0000 !important;
}

.global-actions button span {
  display: none;
}

.popover-dialog > header:first-child {
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: .5rem .75rem;
  font-weight: 500;
  display: flex;
}

.popover-dialog > header:first-child button {
  color: var(--text-main);
  margin: 0;
  padding: .25rem;
  line-height: 1;
  background-color: #0000 !important;
}

.popover-dialog {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1rem;
  left: auto;
  right: 1rem;
}

.popover-dialog :not(header) {
  font-size: .9rem;
}

.popover-dialog ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.popover-dialog ul li {
  color: var(--text-main);
  border-bottom: 1px solid var(--border);
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.popover-dialog ul li:last-child {
  border-bottom: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .popover-dialog::backdrop {
    opacity: 0;
  }

  .popover-dialog[open]::backdrop {
    opacity: 1;
  }

  .popover-dialog, .popover-dialog::backdrop {
    transition: transform .3s, opacity .3s, display .3s allow-discrete, overlay .3s allow-discrete;
  }

  .popover-dialog[open] {
    opacity: 1;
    transform: translateY(0);
  }

  .popover-dialog {
    opacity: 0;
    transform: translateY(-16px);
  }

  @starting-style {
    .popover-dialog[open] {
      opacity: 0;
      transform: translateY(-16px);
    }

    .popover-dialog[open]::backdrop {
      opacity: 0;
    }
  }
}

.popover-dialog--history ul {
  max-width: 500px;
}

.popover-dialog--history ul li {
  word-break: break-all;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: .5rem .75rem;
}

.history-modal__actions {
  gap: .25rem;
  display: flex;
}

.history-modal__actions button, .history-modal__actions custom-clipboard-copy::part(button) {
  border-radius: var(--border-radius);
  color: var(--text-main);
  cursor: pointer;
  border: 0;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  margin: 0;
  padding: .25rem .5rem;
  font-size: 1rem;
  line-height: 1;
  display: flex;
  background-color: #0000 !important;
}

.history-modal__actions custom-clipboard-copy::part(button--success) {
  color: var(--success-color);
}

.history-modal__actions .history-modal__delete-action {
  color: var(--error-color);
  margin-right: -.5rem;
}

.popover-dialog--settings ul li input[type="checkbox"] {
  margin: .15rem .5rem 0 0;
}

.popover-dialog--settings ul li label {
  cursor: pointer;
  flex: 1;
  margin: 0;
  padding: .75rem;
  display: flex;
}

.popover-dialog--settings fieldset {
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.popover-dialog--settings fieldset legend {
  padding: .5rem .75rem 0;
  font-weight: 500;
}

.empty-history-button {
  color: var(--empty-history-button-color);
  width: calc(100% - 1.5rem);
  margin: .75rem 0 .75rem .75rem;
  background-color: var(--error-color) !important;
}

a-tab-group:not(:defined) {
  display: none;
}

a-tab-group::part(tabs) {
  border-radius: var(--border-radius);
  padding: 0;
}

a-tab {
  flex: 1;
  display: flex;
}

a-tab::part(base) {
  background-color: var(--background);
  transition: background-color var(--animation-duration) ease-in-out, color var(--animation-duration) ease-in-out;
  flex: 1;
  justify-content: center;
  padding: .5rem .75rem;
}

a-tab:focus-visible::part(base) {
  box-shadow: inset 0 0 0 2px var(--focus);
  outline: none;
}

a-tab[selected]::part(base) {
  background-color: var(--accent);
  color: var(--background-body);
}

.visually-hidden {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.text-underline {
  text-decoration: underline !important;
}

@media (width >= 37.5rem) {
  h1 {
    font-size: 2rem;
  }

  .scan-instructions p {
    max-width: none;
  }

  capture-photo {
    max-width: var(--container-max-width);
  }

  .global-actions button span {
    display: inline;
  }
}

@media (height >= 43.75rem) {
  header {
    margin: 3rem 0;
  }
}

@media screen and (width <= 20rem) {
  a-tab span {
    font-size: .9rem;
  }

  a-tab svg {
    display: none;
  }
}
/*# sourceMappingURL=index.3a4f330a.css.map */
